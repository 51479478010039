(function () {
  const wizard = document.querySelector('.wizard');
  if (!wizard) {
    return;
  }
  const wizardToggle = document.querySelector('.wizard-toggle');
  const backButton = document.querySelector('.wizard-btn-back');
  const nextButton = document.querySelector('.wizard-btn-next');
  const contents = document.querySelectorAll('.wizard-body-content');
  const wizardHeader = document.querySelector('.wizard-header');
  const wizardDeskVert = wizard.querySelector('.wizard-desktop-vertical');

  // Larguras máximas para a troca de layout (horizontal/vertical)
  const LARGURA_TELA_MAX_HORIZONTAL = 576;
  const LARGURA_TELA_MAX_VERTICAL = 768;

  // Aplica a classe step-vertical no wizard caso ele esteja no modo desktop vertical
  function aplicarStepVertical() {
    const step = wizard.querySelector('.step');

    if (wizardDeskVert) step.classList.add('step-vertical');
  }

  let estadoAtual = ''; // Estado atual do layout (mobile ou desktop)
  let textoSpan = ''; // Texto temporário para o botão "Próximo"

  // Ajusta os estilos do wizard com base na largura da tela
  function ajustarLarguraTela() {
    const step = wizard.querySelector('.step');
    const LARGURA_TELA = window.innerWidth;

    let novoEstado = null;

    if (
      (LARGURA_TELA < LARGURA_TELA_MAX_VERTICAL && wizardDeskVert) ||
      LARGURA_TELA < LARGURA_TELA_MAX_HORIZONTAL
    ) {
      novoEstado = 'mobile';
    } else {
      novoEstado = 'desktop';
    }

    // Aplica os estilos apenas se o estado mudou
    if (novoEstado !== estadoAtual) {
      estadoAtual = novoEstado;
      if (novoEstado === 'mobile') {
        aplicarEstilosMobile();
      } else {
        aplicarEstilosDesktop();
      }
    }

    aplicarStepVertical();
  }

  // Adiciona o icone de carregando no botão "Próximo" caso tenha o atributo data-loading
  function adicionarIconeCarregando() {
    if (estadoAtual == 'mobile') {
      nextButton.innerHTML = `<i class="fas fa-circle-notch fa-spin loading-icon"></i>`;
    } else {
      const loadingIcon = nextButton.querySelector('.loading-icon');
      if (!loadingIcon) {
        nextButton.innerHTML = `<i class="fas fa-circle-notch fa-spin loading-icon"></i> ${nextButton.innerHTML}`;
      }
      if (nextButton.hasAttribute('data-loading-text')) {
        const loadingText = nextButton.getAttribute('data-loading-text');
        let span = nextButton.querySelector('span[data-text="true"]');
        if (!span) {
          span = document.createElement('span');
          span.setAttribute('data-text', 'true');
          span.textContent = nextButton.textContent.trim();
          nextButton.textContent = '';
          nextButton.appendChild(span);
        }

        textoSpan =
          span.textContent.trim() !== loadingText
            ? span.textContent.trim()
            : 'Avançar';

        span.textContent = loadingText;
      }
    }
  }

  // Aplica os estilos de mobile
  function aplicarEstilosMobile() {
    wizard.classList.add('wizard-vertical');
    wizardToggle.style.display = 'flex';
    wizardHeader.style.display = 'none';
    backButton.innerHTML = '<i class="fas fa-chevron-left"></i>';
    nextButton.innerHTML = '<i class="fas fa-chevron-right"></i>';

    if (nextButton.hasAttribute('data-loading')) {
      const icon = nextButton.querySelector('i');
      icon.setAttribute('data-icon', 'true');
    }
    const loading = nextButton.getAttribute('data-loading');
    if (loading === 'true') {
      adicionarIconeCarregando();
    }

    atualizarBotaoEstilos('btn-secondary', 'btn-secondary-icon');
    atualizarBotaoEstilos('btn-primary', 'btn-primary-icon');
  }

  // Aplica os estilos de desktop
  function aplicarEstilosDesktop() {
    wizard.classList.remove('wizard-vertical');
    wizardToggle.style.display = 'none';
    if (wizardToggle.classList.contains('wizard-toggle-expanded')) {
      resetarBotaoToggle();
    }
    wizardHeader.style.display = 'block';
    backButton.innerHTML = 'Voltar';

    if (nextButton.hasAttribute('data-loading-text')) {
      nextButton.innerHTML = `<span data-text="true">Avançar</span>`;
    } else {
      nextButton.innerHTML = 'Avançar';
    }

    const loading = nextButton.getAttribute('data-loading');
    if (loading === 'true') {
      adicionarIconeCarregando();
    }

    verificarUltimaEtapa();

    atualizarBotaoEstilos('btn-secondary-icon', 'btn-secondary');
    atualizarBotaoEstilos('btn-primary-icon', 'btn-primary');
  }

  // Atualiza as classes dos botões
  function atualizarBotaoEstilos(removedClass, addedClass) {
    backButton.classList.replace(removedClass, addedClass);
    nextButton.classList.replace(removedClass, addedClass);
  }

  // Verifica se o wizard está na última etapa caso tenha o atributo data-last-label muda o texto do botão de 'proximo'
  function verificarUltimaEtapa() {
    const activeStep = wizard.querySelector('.step-progress-item.active');
    const allSteps = wizard.querySelectorAll('.step-progress-item');

    const isVertical = nextButton
      .closest('.wizard')
      .classList.contains('wizard-vertical');

    const isLastStep =
      Array.from(allSteps).indexOf(activeStep) === allSteps.length - 1;

    if (
      isLastStep &&
      nextButton.hasAttribute('data-last-label') &&
      !isVertical
    ) {
      const lastLabel = nextButton.getAttribute('data-last-label');
      if (nextButton.hasAttribute('data-loading-text')) {
        nextButton.innerHTML = `<span data-text="true">${lastLabel}</span>`;
      } else {
        nextButton.textContent = lastLabel;
      }
    } else {
      if (estadoAtual == 'desktop') {
        if (nextButton.hasAttribute('data-loading-text')) {
          nextButton.innerHTML = `<span data-text="true">Avançar</span>`;
        } else {
          nextButton.textContent = 'Avançar';
        }
      }
    }
    const loading = nextButton.getAttribute('data-loading');
    if (loading === 'true') {
      adicionarIconeCarregando();
    }
  }

  // Reseta o botão de toggle para o estado inicial
  function resetarBotaoToggle() {
    wizardToggle.classList.remove('wizard-toggle-expanded');
    wizardToggle.setAttribute('aria-expanded', 'false');
    wizardToggle
      .querySelector('i')
      .classList.replace('fa-chevron-up', 'fa-chevron-down');
    wizardHeader.style.display = 'none';
  }

  // Atualiza as classes do status do toggle do wizard com base no estado atual
  function atualizarWizardItemClasses() {
    const activeStep = document.querySelector('.step-progress-item.active');
    const wizardItem = document.querySelector('.wizard-status');
    if (activeStep && wizardItem) {
      resetarClasses(wizardItem);
      adicionarClassesBaseadasNoStatus(activeStep, wizardItem);

      // Atualiza o número do step apenas se o wizard for do tipo ordenado
      const stepContainer = wizard.querySelector('.step-progress ');
      if (stepContainer && stepContainer.classList.contains('step-ordered')) {
        atualizarStepNum(activeStep);
      }
    }
  }

  // Atualiza o número do step no status do wizard
  function atualizarStepNum(activeStep) {
    const wizardStatus = document.querySelector('.wizard-status.wizard-item');
    if (activeStep && wizardStatus) {
      const stepNum = activeStep.getAttribute('step-num');
      wizardStatus.setAttribute('step-num', stepNum);
    }
  }

  // Reseta as classes do status do toggle do wizard
  function resetarClasses(element) {
    element.className = 'wizard-status';
  }

  // Adiciona classes no status do toggle do wizard com base no status do step ativo
  function adicionarClassesBaseadasNoStatus(activeStep, wizardItem) {
    const stepIcon = activeStep.querySelector('.step-icon');
    if (stepIcon) {
      stepIcon.classList.forEach((cls) => {
        wizardItem.classList.add(cls);
        wizardItem.classList.remove('step-icon');
      });
    } else {
      const stepContainer = wizard.querySelector('.step-progress ');
      if (stepContainer && stepContainer.classList.contains('step-ordered')) {
        wizardItem.classList.add('wizard-ordered');
      } else {
        wizardItem.classList.add('wizard-bullet');
      }
    }

    if (activeStep.classList.contains('step-success')) {
      wizardItem.classList.add('wizard-success');
    } else if (activeStep.classList.contains('step-warning')) {
      wizardItem.classList.add('wizard-warning');
    } else if (activeStep.classList.contains('step-danger')) {
      wizardItem.classList.add('wizard-danger');
    }

    if (
      wizardItem.classList.contains('wizard-success') ||
      wizardItem.classList.contains('wizard-warning') ||
      wizardItem.classList.contains('wizard-danger') ||
      wizardItem.classList.contains('wizard-bullet') ||
      wizardItem.classList.contains('wizard-ordered')
    ) {
      wizardItem.classList.add('wizard-item');
    }
  }

  // Atualiza o texto do toggle do wizard baseado no step ativo
  function atualizarWizardTexto() {
    const activeStep = document.querySelector(
      '.step-progress-item.active .step-label'
    );
    const wizardText = document.querySelector('.wizard-text');
    if (activeStep && wizardText) {
      wizardText.textContent = activeStep.textContent;
    }
  }

  // Observer para monitorar mudanças de classes nos steps
  function configurarObservadorDeMutacoes() {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        // Verifica mudanças de atributos
        if (mutation.type === 'attributes') {
          // Observa mudanças na classe
          if (mutation.attributeName === 'class') {
            const activeContentIndex = Array.from(contents).findIndex(
              (content) => content.classList.contains('active') // Encontra o índice do conteúdo ativo
            );
            if (activeContentIndex !== -1) {
              atualizarWizardTexto(); // Atualiza o texto do wizard com base no step ativo
              atualizarWizardItemClasses(); // Atualiza as classes dos itens do wizard
              verificarUltimaEtapa(); // Verifica se o step atual é o último
            }

            if (
              mutation.target.classList.contains('step-progress-item') &&
              mutation.target.classList.contains('active')
            ) {
              if (mutation.target.classList.contains('step-danger')) {
                const wizardItem = document.querySelector('.wizard-status');
                adicionarClassesBaseadasNoStatus(mutation.target, wizardItem); // Adiciona classes relacionadas ao status
              }
            }
          }

          // Observa mudanças no atributo `data-loading` do botão "Next"
          if (mutation.target === nextButton) {
            if (mutation.attributeName === 'data-loading') ajustarNextButton(); // Ajusta o botão com base no estado de carregamento
          }
        }
      }
    });

    // Observa mudanças nos elementos do conteúdo
    contents.forEach((content) => {
      observer.observe(content, { attributes: true });
    });

    // Observa mudanças nos itens do progresso
    const stepItems = document.querySelectorAll('.step-progress-item');
    stepItems.forEach((stepItem) => {
      observer.observe(stepItem, { attributes: true });
    });

    // Configura o observer para monitorar o botão "Next"
    observer.observe(nextButton, { attributes: true });
  }

  // Função para ajustar o botão "Next" com base em atributos ou estado atual
  function ajustarNextButton() {
    if (estadoAtual == 'mobile') {
      // No estado "mobile", o botão exibe apenas um ícone de seta
      if (nextButton.getAttribute('data-loading') === 'false') {
        nextButton.innerHTML = '<i class="fas fa-chevron-right"></i>';
      }
    } else {
      let span = nextButton.querySelector('span[data-text="true"]');
      if (!span) return;

      // Altera o texto do botão para o texto de carregamento se o atributo data-loading for 'true'
      if (
        nextButton.getAttribute('data-loading') === 'true' &&
        nextButton.hasAttribute('data-loading-text')
      ) {
        const loadingText = nextButton.getAttribute('data-loading-text');
        span.textContent = loadingText;
      } else {
        // Caso contrário, redefine o botão para seu texto original
        nextButton.innerHTML = `<span data-text="true">${textoSpan}</span>`;
      }
    }
  }

  // Configura o evento do botão de toggle para alternar a exibição do cabeçalho
  function configurarEventoToggle() {
    if (!wizardToggle) return;
    wizardToggle.addEventListener('click', (event) => {
      event.stopPropagation();
      const isExpanded = wizardToggle.classList.toggle(
        'wizard-toggle-expanded'
      );
      wizardToggle.setAttribute('aria-expanded', isExpanded);
      wizardHeader.style.display = isExpanded ? 'block' : 'none';
      atualizarIconeToggle(isExpanded);
    });

    document.addEventListener('click', () => {
      if (wizardToggle.classList.contains('wizard-toggle-expanded')) {
        resetarBotaoToggle();
      }
    });
  }

  // Atualiza o ícone do botão de toggle com base no estado expandido/colapsado
  function atualizarIconeToggle(isExpanded) {
    if (isExpanded) {
      wizardToggle
        .querySelector('i')
        .classList.replace('fa-chevron-down', 'fa-chevron-up');
    } else {
      wizardToggle
        .querySelector('i')
        .classList.replace('fa-chevron-up', 'fa-chevron-down');
    }
  }

  // Scrolla a tela para baixo ao abrir um select
  function configurarRolagemSelect() {
    const comboButtons = wizard.querySelectorAll('.combo-input');
    const wizardBody = wizard.querySelector('.wizard-body');
    const wizardBodyStyle = getComputedStyle(wizardBody);
    const marginBottom = parseFloat(wizardBodyStyle.marginBottom);

    comboButtons.forEach(function (comboButton) {
      comboButton.addEventListener('click', function () {
        const comboPosition = comboButton.getBoundingClientRect();
        const wizardBodyPosition = wizardBody.getBoundingClientRect();

        const offset =
          comboPosition.top - wizardBodyPosition.top + wizardBody.scrollTop;

        wizardBody.scrollTo({
          top: offset - wizardBody.clientHeight / 2 + marginBottom,
          behavior: 'smooth',
        });
      });
    });
  }

  ajustarLarguraTela();
  atualizarWizardItemClasses();
  atualizarWizardTexto();
  verificarUltimaEtapa();
  configurarObservadorDeMutacoes();
  configurarEventoToggle();
  configurarRolagemSelect();

  window.addEventListener('resize', ajustarLarguraTela);
})();
